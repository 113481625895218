import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import classNames from "classnames";

import styles from "./PortfolioBlock.module.css";
import BlockHeader from "../block-header/BlockHeader";
import WorkList from "./work-list/WorkList";
import WorkInfo from "./work-info/WorkInfo";
import SphereAnimation from "../sphere-animation/SphereAnimation";
import FETCH_PORTFOLIO_PAGE from "../../api/Portfolio.service";
import { BrowserView, MobileView } from "react-device-detect";
import PortfolioPopup from "./portfolio-popup/PortfolioPopup";

const PortfolioBlock = () => {
    const CONTENT_LLAMA_ID = "6203aecae5583005751badd1";
    const COVEREPORT_ID = "620525f3e5583005751bae0e";
    const DIGITAL_PAYMENT_PLATFORM_ID = "6273ba03d6c4180338268d11";
    const STORY_TRACKS_ID = "6273ba37d6c4180338268d12";
    const YACHTCHARTERFINDER_ID = "6273ba67d6c4180338268d13";
    const BENEFIT_AT_WORK_ID = "6273bb46d6c4180338268d14";
    const CHALL_ID = "65eebc8d5b4c8832f9cfafe7";
    const TQG_PROJECT_ID = "65eebdad5b4c8832f9cfafe8";
    const CYBERSIGHT_ID = "65eebeb55b4c8832f9cfafe9";

    const { loading, error, data } = useQuery(FETCH_PORTFOLIO_PAGE);
    const portfolioData = data?.portfolioPage || null;
    const [activeItem, setActiveItem] = useState(null);
    const toggleInfoBlock = (e, item) => {
        e.stopPropagation();
        setActiveItem(item);
    };

    const handleClosePopup = () => {
        setActiveItem(null);
    };

    const sortedProjects = (projects) => {
        if (!projects) {
            return [];
        }

        const order = [CYBERSIGHT_ID, TQG_PROJECT_ID, CHALL_ID, BENEFIT_AT_WORK_ID, YACHTCHARTERFINDER_ID, STORY_TRACKS_ID, DIGITAL_PAYMENT_PLATFORM_ID, COVEREPORT_ID, CONTENT_LLAMA_ID];

        return [...projects].sort((a, b) =>
            order.findIndex(id => a.id === id) - order.findIndex(id => b.id === id));
    };

    return (
        <div className={styles.portfolio}>
            <div className={styles.worksWrapper}>
                {portfolioData &&
                    <BlockHeader header={portfolioData} />}

                <WorkList works={sortedProjects(portfolioData?.projects)}
                          toggleInfoBlock={toggleInfoBlock}
                          activeItem={activeItem} />
            </div>

            {activeItem ?
                <>
                    <BrowserView className={classNames(styles.workInfoWrapper, "right-directed-border-appearance")}>
                        <div className={classNames(styles.workInfoContent, "content-appearance")}>
                            <WorkInfo activeItem={activeItem} />
                        </div>
                    </BrowserView>
                    <MobileView>
                        <PortfolioPopup activeItem={activeItem} handleClosePopup={handleClosePopup} />
                    </MobileView>
                </>
                :
                <div className={styles.animationWrapper}>
                    <SphereAnimation color="white" />
                </div>
            }
        </div>
    );
};

export default PortfolioBlock;
