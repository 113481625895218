import React, {useState} from "react";
import classNames from 'classnames';
import styles from './PortfolioWorkItem.module.css';
import icon from '../../../images/icons/portfolio/project-normal.png'
import iconHover from '../../../images/icons/portfolio/project-hover.png'
import iconActive from '../../../images/icons/portfolio/project-active.png'


const PortfolioWorkItem = ({ work, toggleInfoBlock, activeItem }) => {
    const [isHover, setIsHover] = useState(false)

    return (
        <div className={styles.wrapper} onClick={(e) => toggleInfoBlock(e, work)}>
            {work === activeItem
                ?
                <img
                    className={styles.icon}
                    src={iconActive}
                    onMouseLeave={() => setIsHover(false)}
                    alt=""
                />
                :
                <img
                    className={styles.icon}
                    src={`${isHover ? iconHover : icon}`}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    alt=""
                />
            }
            <span className={classNames(styles.title, 'accent-text')}>{ work.title }</span>
        </div>
    )
};

export default PortfolioWorkItem;
