import React from 'react';
import classNames from "classnames";
import styles from './PortfolioPopup.module.css';
import WorkInfo from "../work-info/WorkInfo";

const PortfolioPopup = ({activeItem, handleClosePopup}) => {

    return (
        <div className={styles.portfolioPopup}>
            <div className={classNames(styles.workInfoContent, 'content-appearance')}>
                <span className={styles.closeBtn} onClick={handleClosePopup}>X</span>
                <WorkInfo activeItem={activeItem}/>
            </div>
        </div>
    );
};

export default PortfolioPopup;